import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../contexts/authContext";
import { FORGOT_PASSWORD, LOGIN } from "../config/router/paths";
import { Link, useNavigate } from "react-router-dom";

export const Register = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();

    const { registro } = useAuthContext();

    const onSubmit = (data) => {
        delete data.confirmPassword;
        setIsLoading(true);
        registro(data);
        reset();
        navigate(LOGIN);
        setIsLoading(false);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center min-vh-100">
                <div className="col-md-6 col-sm-12">
                    <div className="card border-0 shadow">
                        <h4 className="text-center my-5">CREAR CUENTA SIREXIA</h4>
                        <form className="mx-5 mb-5" onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nombre completo:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                    id="name"
                                    {...register("name", {
                                        required: "El nombre es requerido",
                                    })}
                                />
                                {errors.name && (
                                    <div className="invalid-feedback small">{errors.name.message}</div>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email:</label>
                                <input
                                    type="email"
                                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                    id="email"
                                    {...register("email", {
                                        required: "El correo electrónico es requerido",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "El correo electrónico no es válido",
                                        },
                                    })}
                                />
                                {errors.email && (
                                    <div className="invalid-feedback small">{errors.email.message}</div>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password:</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                    id="password"
                                    {...register("password", {
                                        required: "La contraseña es requerida",
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                                            message: 'La contraseña debe contener al menos una minúscula, una mayúscula, un número y un carácter especial @$!%*?& y tener una longitud mínima de 9 caracteres'
                                        },
                                        minLength: {
                                            value: 9,
                                            message: "La contraseña debe tener al menos 9 caracteres",
                                        },
                                    })}
                                />
                                {errors.password && (
                                    <div className="invalid-feedback">{errors.password.message}</div>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">Confirmar contraseña:</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
                                    id="confirmPassword"
                                    {...register("confirmPassword", {
                                        required: "Debe confirmar la contraseña",
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                                            message: 'La contraseña debe contener al menos una minúscula, una mayúscula, un número y un carácter especial @$!%*?&'
                                        },
                                        validate: (value) =>
                                            value === watch("password") || "Las contraseñas no coinciden",
                                    })}
                                />
                                {errors.confirmPassword && (
                                    <div className="invalid-feedback small">
                                        {errors.confirmPassword.message}
                                    </div>
                                )}
                            </div>

                            <div className="form-check mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="showPasswordCheckbox"
                                    checked={showPassword}
                                    onChange={toggleShowPassword}
                                />
                                <label className="form-check-label" htmlFor="showPasswordCheckbox">
                                    Mostrar contraseñas
                                </label>
                            </div>

                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-danger" disabled={isLoading}>
                                    {isLoading ? "Creando cuenta..." : "Crear cuenta"}
                                </button>
                            </div>
                            <br/>
                            <div className="mb-3 text-center">
                                <small>Ir a la ventana de ingreso: </small>
                                <Link className="small" to={LOGIN}>aquí!</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
