import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuthContext} from "../contexts/authContext";
import {LOGIN} from "../config/router/paths";

const ActivarPassword = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const {activatePassword} = useAuthContext();

    const [data, setData] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            setData( await activatePassword( searchParams.get('at') ) );
        };
        fetchData();
    }, [activatePassword, searchParams]);

    // Redirección automática después de 3 segundos
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(LOGIN);
        }, 10000);

        return () => clearTimeout(timer); // Limpieza del temporizador en caso de desmontaje del componente
    }, [navigate, data]);

    // Mientras esperamos la respuesta
    if (data === null) {
        return <div>Cargando...</div>;
    }




    return (
        <div>
            <h1>Activación de nueva contraseña SIREXIA</h1>
        </div>
    );
};

export default ActivarPassword;