import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../contexts/authContext";
import { FORGOT_PASSWORD, REGISTER } from "../config/router/paths";
import { Link } from "react-router-dom";

export const Login = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const { login } = useAuthContext();

    const onSubmit = (data) => {
        setIsLoading(true);
        login(data);
        reset();
        setIsLoading(false);
    };

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center min-vh-100">
                <div className="col-md-6 col-sm-12">
                    <div className="card border-0 shadow">
                        <h4 className="text-center my-5">INGRESO SIREXIA</h4>
                        <form className="mx-5 mb-5" onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email:</label>
                                <input
                                    type="email"
                                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                    id="email"
                                    {...register("email", {
                                        required: "El correo electrónico es requerido",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "El correo electrónico no es válido",
                                        },
                                    })}
                                />
                                {errors.email && (
                                    <div className="invalid-feedback small">{errors.email.message}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password:</label>
                                <input
                                    type="password"
                                    className={`form-control ${errors.password ? "is-invalid" : ""}`}
                                    id="password"
                                    {...register("password", {
                                        required: "La contraseña es requerida",
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                                            message: 'La contraseña debe contener al menos una minúscula, una mayúscula, un número y un carácter especial @$!%*?& y tener una longitud mínima de 9 caracteres'
                                        },
                                        minLength: {
                                            value: 9,
                                            message: "La contraseña debe tener al menos 9 caracteres",
                                        },
                                    })}
                                />
                                {errors.password && (
                                    <div className="invalid-feedback">{errors.password.message}</div>
                                )}
                            </div>
                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-danger" disabled={isLoading}>
                                    {isLoading ? "Iniciando..." : "Iniciar sesión"}
                                </button>
                            </div>
                            <br/>
                            <div className="mb-3 text-center">
                                <p>
                                    <small>
                                        Envía tus observaciones y dudas <Link to="mailto:giidac@unipamplona.edu.co">aquí!</Link> (giidac@unipamplona.edu.co)
                                    </small>
                                </p>
                                <small>¿No tienes cuenta Sirexia?, crea una </small>
                                <Link className="small" to={REGISTER}>aquí!</Link>
                                <br />
                                <small>Olvidé mi contraseña, </small>
                                <Link className="small" to={FORGOT_PASSWORD}>recordar!</Link>                               

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
