import axios from 'axios';

const addTokenToRequest = (config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};

const setContentType = (config) => {
    if ((config.method === 'post' || config.method === 'put') && config.url.includes('article')) {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else {
        config.headers['Content-Type'] = 'application/json';
    }
    return config;
};

// Creamos la instancia de Axios
const axiosInstance = axios.create({
    //baseURL: 'http://localhost:4023/api/',
    baseURL: 'https://apigiidac.unipamplona.edu.co/api/',
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Agregamos el interceptor de solicitud para modificar la configuración antes de enviarla
axiosInstance.interceptors.request.use(
    (config) => {
        config = addTokenToRequest(config);
        config = setContentType(config);
        return config;
    },
    (error) => Promise.reject(error)
);

// Agregamos el interceptor de respuesta para manejar errores globalmente
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        // Aquí puedes realizar el manejo global de errores, por ejemplo, mostrar una notificación al usuario
        console.error('Error en la solicitud:', error);
        return Promise.reject(error);
    }
);

// Funciones CRUD genéricas que usan la instancia de Axios
export const api = {
    get: (url) => axiosInstance.get(url),
    post: (url, data) => axiosInstance.post(url, data),
    put: (url, data) => axiosInstance.put(url, data),
    delete: (url) => axiosInstance.delete(url),
    getPdf: (url) => axiosInstance.get(url, { responseType: 'blob' }),
};
