import React, {useEffect, useState} from 'react';
import {useArticuloContext} from "../../contexts/articuloContext";
import {useNavigate} from "react-router-dom";
import {ACTUALIZAR_ARTICULO} from "../../config/router/paths";

const MisArticulos = () => {

    const navigate = useNavigate();
    const {obtenerArticulos, getPdf, eliminarArticulo} = useArticuloContext();

    const [data, setData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [articleToDelete, setArticleToDelete] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setData( await obtenerArticulos() );
        };
        fetchData();
    }, [obtenerArticulos]);


    if (data === null) {
        return <div>Cargando...</div>;
    }

    // Funciónpara ver PDF
    const handleFileClick = (file) => {
        getPdf(file);
    }

    // Función para manejar el clic en el botón "Editar"
    const handleEditarClick = (art) => {
        navigate(ACTUALIZAR_ARTICULO, {state: { data: art}});
    };

    // Función para manejar el clic en el botón "Eliminar"
    const handleEliminarClick = (id) => {
        // Setear el artículo a eliminar y mostrar el modal de confirmación
        setArticleToDelete(id);
        setShowModal(true);
    };

    // Función para confirmar el borrado del artículo
    const confirmDelete = async () => {
        setIsLoading(true);
        setShowModal(false);

        await eliminarArticulo(articleToDelete);

        setData( await obtenerArticulos() );
        setIsLoading(false);
    };

    // Función para cancelar el borrado del artículo
    const cancelDelete = () => {
        setArticleToDelete(null);
        setShowModal(false);
    };


    return (
        <div className="container mt-3 card border-0 shadow-lg p-5">
            <h3 className="mb-3 text-center">Mis artículos SIREXIA</h3>
            <div className="table-responsive">
                <table className="table table-sm table-striped table-bordered">
                    <thead>
                    <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Título</th>
                        <th className="text-center">Observaciones</th>
                        <th className="text-center">Decisión</th>
                        <th className="text-center">Modalidad</th>
                        <th className="text-center">Opciones</th>

                    </tr>
                    </thead>
                    <tbody>
                    {data &&
                        data.map((atr) => (
                            <tr key={atr._id}>
                                <td>{atr.code}</td>
                                <td>{atr.title}</td>
                                <td>{atr.observations}</td>
                                <td>{atr.decision}</td>
                                <td>{atr.modality}</td>
                                <td className="text-center">
                                    <button
                                        className="btn btn-success btn-sm"
                                        onClick={() => handleFileClick(atr.pdfs[atr.pdfs.length - 1])}
                                    >
                                        Pdf
                                    </button>
                                    <button
                                        className="btn btn-warning btn-sm mx-3"
                                        onClick={() => handleEditarClick(atr)} // Agregar la función para manejar la edición
                                    >
                                        Editar
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleEliminarClick(atr._id)} // Agregar la función para manejar la eliminación
                                    >
                                        Eliminar
                                    </button>
                                </td>


                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal de confirmación */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirmar eliminación artículo</h5>
                            <button type="button" className="btn-close" onClick={cancelDelete}></button>
                        </div>
                        <div className="modal-body">
                            <p>¿Está seguro(a) que desea eliminar este artículo?. La acción es irreversible.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={cancelDelete}>
                                Cancelar
                            </button>
                            <button type="button" className="btn btn-danger" onClick={confirmDelete} disabled={isLoading}>
                                {isLoading ? "Eliminando..." : "Eliminar"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && <div className="modal-backdrop fade show" onClick={cancelDelete}></div>}

        </div>
    );
};

export default MisArticulos;
