import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../contexts/authContext";
import { Link } from "react-router-dom";
import { LOGIN } from "../config/router/paths";

export const RecordarPassword = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const { recordarPassword } = useAuthContext();

    const onSubmit = async (data) => {
        setIsLoading(true);
        await recordarPassword(data);
        reset();
        setIsLoading(false);
    };

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center min-vh-100">
                <div className="col-md-6 col-sm-12">
                    <div className="card border-0 shadow">
                        <h4 className="text-center my-5">RECORDAR CONTRASEÑA</h4>
                        <form className="mx-5 mb-5" onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email:</label>
                                <input
                                    type="email"
                                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                    id="email"
                                    {...register("email", {
                                        required: "El correo electrónico es requerido",
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "El correo electrónico no es válido",
                                        },
                                    })}
                                />
                                {errors.email && (
                                    <div className="invalid-feedback small">{errors.email.message}</div>
                                )}
                            </div>

                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-danger" disabled={isLoading}>
                                    {isLoading ? "Enviando..." : "Recordar contraseña"}
                                </button>
                            </div>
                            <div className="my-3 text-center">
                                <p>
                                    <small>
                                        Si no recuerda su email, por favor envía un correo realizando la solicitud
                                        <Link to="mailto:giidac@unipamplona.edu.co"> aquí!</Link>
                                    </small>
                                </p>
                            </div>
                            <div className="mb-3 text-center">
                                <small>Ir a la ventana de ingreso: </small>
                                <Link className="small" to={LOGIN}>aquí!</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
